import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageLinkRow from "../../components/ImageLinkRow"


const IndexPage = () => {
    const root = "/img/download/leaders/digital"

    return (
        <Layout pageTitle="Leaders - Press Releases">
            <Row>
                <Col>
                    <Link to="/leaders">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}></Col>
            </Row>
            <ImageLinkRow
                name="New Club Announcement"
                text={`Use this template to create a press release to launch your Coffee & Coworking event.`}
                image={`${root}/press.png`}
                url="https://docs.google.com/document/d/1RqxdKDNZEEtcIhF3HaS5b3F_q6v_yrbt/edit?usp=sharing&ouid=100648430204978709054&rtpof=true&sd=true"
            />
          
        </Layout>
    )
}

const ValueRow = ({ name, href }) => {
    return (
        <Row className="mt-5">
            <Col>
                <h3>{name}</h3>
                <a
                    href={href}
                    className="btn btn-primary m-3"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Download Document
                </a>
            </Col>
        </Row>
    )
}

export default IndexPage
